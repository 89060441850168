import React, {useEffect, useRef, useState} from 'react';
import './Homepage.scss';
import LockerCard from "../LockerCard/LockerCard";
import Faqs from "./Faqs/Faqs";
import Footer from "../Footer/Footer";
import {motion, useInView} from 'framer-motion';
import {loadStripe} from "@stripe/stripe-js";
import {useUser} from "../Auth/UserContext";
import {useModal} from "../Auth/ModalContext";
import Select, {components} from 'react-select';
import Modal from "../Modal/Modal";
import SummaryModalContent from "./SummaryModalContent";
import SuccessModal from "./SuccessModal";
import Cookies from "js-cookie";
import MapComponent from "../GoogleMaps/GoogleMapsComponent";


const HomePage = () => {
    const [stripeProducts, setStripeProducts] = useState([]);
    const {user} = useUser();
    const [pendingCheckoutProductId, setPendingCheckoutProductId] = useState(null);
    const {setAuthSuccessCallback, showSummaryModal, setShowSummaryModal, showSuccessModal, setShowSuccessModal, setShowRegisterModal} = useModal();
    const [selectedLocker, setSelectedLocker] = useState(null);
    const [selectedDuration, setSelectedDuration] = useState(null);
    const [availability, setAvailability] = useState({M: 0, L: 0, XL: 0});
    const [transactionDetails, setTransactionDetails] = useState(null);
    const lockerChoicePackRef = useRef(null);

    const onAuthSuccess = async () => {
        if (pendingCheckoutProductId) {
            await handleCheckout(pendingCheckoutProductId);
            setPendingCheckoutProductId(null);
        }
    };
    const openSummaryModal = () => {
        if (selectedLocker && selectedDuration) {
            setShowSummaryModal(true);
        }
    };

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const sessionId = query.get('sessionId');
        const token = Cookies.get('jwt');
        const fetchStripeProducts = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + '/api/lockers/products', {
                    method: 'GET',
                    headers: {'Content-Type': 'application/json'},
                });
                if (response.ok) {
                    const data = await response.json();
                    setStripeProducts(data);
                } else {
                    console.error("HTTP error:", response.status, response.statusText);
                }
            } catch (error) {
                console.error("Fetch error:", error.message);
            }
        };
        const fetchTransactionDetails = async (sessionId) => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/rents/transaction/${sessionId}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch transaction details');
                }
                const data = await response.json();
                setTransactionDetails(data);
                setShowSuccessModal(true);
            } catch (error) {
                console.error('Error fetching transaction details:', error);
            }
        };
        const fetchAvailability = async () => {
            try {
                fetch(`${process.env.REACT_APP_API_URL}/api/lockers/count-available`)
                    .then(response => response.json())
                    .then(data => setAvailability(data))
                    .catch(error => console.error('Error fetching locker availability:', error));
            } catch (error) {
                console.error('Error fetching locker availability:', error);
            }
        }

        if (sessionId) {
            fetchTransactionDetails(sessionId);
        }
        fetchStripeProducts();
        fetchAvailability()

    }, []);

    //DropDown
    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <img src="/imgs/arrow.svg" alt="arrow"/>
            </components.DropdownIndicator>
        );
    };
    const options = selectedLocker ? [
        {value: '3h', label: `3h(${selectedLocker.prices['3h']}€)`},
        {value: '5h', label: `5h(${selectedLocker.prices['5h']}€)`},
        {value: '24h', label: `24h(${selectedLocker.prices['24h']}€)`},
    ] : [];
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#186AB2' : provided.backgroundColor,
            color: state.isSelected ? 'white' : `#616161`,
            ':active': {
                ...provided[':active'],
                backgroundColor: state.isSelected ? 'blue' : provided[':active'].backgroundColor,
            },
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
    };

    function getAvailabilityWarning(availability) {
        const totalLockers = Object.values(availability).reduce((sum, num) => sum + num, 0);
        if (totalLockers >= 10) {
            return 'more than 10 lockers available';
        } else if (totalLockers < 10 && totalLockers >= 5) {
            return 'less than 10 lockers available';
        } else if (totalLockers < 5 && totalLockers > 2) {
            return 'less than 5 lockers available!';
        } else if (totalLockers === 2) {
            return 'only 2 lockers available!';
        } else if (totalLockers === 1) {
            return 'only 1 locker available!';
        } else {
            return 'No lockers available.';
        }
    }

    //Animations
    //Introduction container animation
    const introTextRef = useRef(null);
    const introImageRef = useRef(null);
    const isIntroTextInView = useInView(introTextRef, {once: true, margin: '-200px 0px'});
    const isIntroImageInView = useInView(introImageRef, {once: true, margin: '-200px 0px'});
    const introTextVariants = {
        visible: {opacity: 1, x: 0},
        hidden: {opacity: 0, x: -50}
    };
    const introImageVariants = {
        visible: {opacity: 1, x: 0},
        hidden: {opacity: 0, x: 50}
    };
    //Locker list animation
    const lockerItemVariants = {
        hidden: {opacity: 0, x: -50},
        visible: {opacity: 1, x: 0}
    };
    const transition = {duration: 1};


    //Locker Card
    const handleCardClick = (locker) => {
        setSelectedLocker(locker);
        setSelectedDuration(null);
        if (lockerChoicePackRef.current) {
            lockerChoicePackRef.current.scrollIntoView({behavior: 'smooth', block: 'center'});
        }

    };

    const lockerData = [
        {size: 'M', dimensions: '50x30x85cm', imageSrc: './imgs/esquema_lockerM.svg', altText: 'M Size Locker'},
        {size: 'L', dimensions: '50x50x85cm', imageSrc: './imgs/esquema_lockerL.svg', altText: 'L Size Locker'},
        {size: 'XL', dimensions: '50x60x85cm', imageSrc: './imgs/esquema_lockerXL.svg', altText: 'XL Size Locker'},
    ];

    // Function to get the price for a specific duration and size
    const getPriceForDurationAndSize = (size, duration) => {
        const product = stripeProducts.find(p => p.name === `Locker ${size} - ${duration}`);
        return product ? product.price : null;
    };

    const updatedLockerData = lockerData.map(locker => {
        locker.lowestPrice = getPriceForDurationAndSize(locker.size, '3h');
        const stripeIds = {
            '3h': stripeProducts.find(p => p.name === `Locker ${locker.size} - 3h`)?.id,
            '5h': stripeProducts.find(p => p.name === `Locker ${locker.size} - 5h`)?.id,
            '24h': stripeProducts.find(p => p.name === `Locker ${locker.size} - 24h`)?.id,
        };
        const prices = {
            '3h': getPriceForDurationAndSize(locker.size, '3h'),
            '5h': getPriceForDurationAndSize(locker.size, '5h'),
            '24h': getPriceForDurationAndSize(locker.size, '24h'),
        };
        return {...locker, stripeIds, prices};
    });

    const handleCheckout = async (productId) => {
        const token = Cookies.get('jwt');

        if (!user) {
            setShowRegisterModal(true);
            setPendingCheckoutProductId(productId);
            setAuthSuccessCallback(() => onAuthSuccess);
            return;
        }

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/rent/create-checkout-session', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({productId, email: user.email}),
            });

            if (!response.ok) {
                const errorResponse = await response.json();
                console.error('Response Error:', errorResponse);
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const {sessionId} = await response.json();
            if (!sessionId) {
                throw new Error('Session ID not received from backend');
            }

            const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
            await stripe.redirectToCheckout({sessionId});
        } catch (error) {
            console.error('Error initiating checkout:', error);
        }
    };
    const scrollToLockers = () => {
        document.getElementById("chooseLockerSection").scrollIntoView({behavior: 'smooth'});
    };
    return (
        <div className={"homepageContainer"}>
            <div className={"heroContainer "}>
                <div className="textContainer container">
                    <h1>
                        Lock the things you <br/> cherish while you cherish Madeira
                    </h1>
                    <p>A secure and high quality network of lockers available 24/7</p>
                    <div className={"heroLocationContainer"}>
                        <p><span className="greenCircle"/>Nearest shop: Travessa do Freitas n°9B, Funchal</p>
                        <p id={"lowAvailabilityWarning"}>{getAvailabilityWarning(availability)}</p>
                        <button onClick={scrollToLockers} className={"c-button bookNow"}>Book Now!</button>
                    </div>
                </div>

                <motion.img
                    src="/imgs/woman_travel.svg"
                    alt="Keep your things safe with us"
                    initial={{opacity: 0, y: 50}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 1}}
                />
            </div>
            <div className={"stepsContainer container"}>
                <div className={"singleStepContainer"}>
                    <div className={"stepNumber"}>
                        <h1>1</h1>
                    </div>
                    <div className={"stepDescription"}>
                        <p> Choose a locker size according to the number and dimensions of your luggage.</p>
                    </div>
                </div>
                <div className={"singleStepContainer"}>
                    <div className={"stepNumber"}>
                        <h1>2</h1>
                    </div>
                    <div className={"stepDescription"}>
                        <p> Select a payment method and complete the reservation. Once you complete this step, you will be able to open the door of the establishment with your smartphone.</p>
                    </div>
                </div>
                <div className={"singleStepContainer"}>
                    <div className={"stepNumber"}>
                        <h1>3</h1>
                    </div>
                    <div className={"stepDescription"}>
                        <p> You will be automatically assigned to a locker that you can open anytime with your smartphone.</p>
                    </div>
                </div>
            </div>
            <div className={"introductionContainer"}>
                <div className={"introductionSubContainer container"}>
                    <h1>Tired of carrying your <br/> luggage around?</h1>
                    <motion.div
                        ref={introTextRef}
                        variants={introTextVariants}
                        initial="hidden"
                        animate={isIntroTextInView ? "visible" : "hidden"}
                        transition={{duration: 1}}
                        className={"introTextContainer"}
                    >
                        <p>
                            Keep it Safe 24/7 has the perfect solution for you to explore the island weight-free and unburdened. <br/><br/>
                            Whether you are waiting for your accommodation check-in time or if you indulged in a bit of shopping extravagance, you can simply drop your bags with us - secure, under constant surveillance, and accessible around the clock.
                        </p>
                    </motion.div>
                    <motion.div
                        ref={introImageRef}
                        variants={introImageVariants}
                        initial="hidden"
                        animate={isIntroImageInView ? "visible" : "hidden"}
                        transition={{duration: 1}}
                        className={"introImageContainer"}>
                        <img src="/imgs/exploring.svg" alt="Explore without constraints."/>
                    </motion.div>
                </div>
            </div>

            <div className={"chooseLockerContainer container"} id="chooseLockerSection">
                <h1>Choose your locker</h1>
                <div className={"lockersContainer"}>
                    {updatedLockerData.map((locker, index) => (
                        <LockerCard
                            key={index}
                            index={index}
                            size={locker.size}
                            dimensions={locker.dimensions}
                            price={locker.lowestPrice}
                            imageSrc={locker.imageSrc}
                            altText={locker.altText}
                            variants={lockerItemVariants}
                            transition={transition}
                            onCardClick={() => handleCardClick(locker)}
                            isSelected={selectedLocker && locker.size === selectedLocker.size}
                            isAvailable={availability[locker.size] > 0}
                        />
                    ))}
                </div>
                <div className={"lockerBookingContainer"}>
                    <div className={"lockerBookingSubContainer"}>
                        <div className={"lockerChoiceContainer"}>
                            <div className={"lockerChoiceTime"}>
                                <img src="/imgs/calendar.svg" alt="Calendar"/>
                                <p>Starting Now</p>
                            </div>
                            <div className={"lockerChoicePack"} ref={lockerChoicePackRef}>
                                <img src="/imgs/clock.svg" alt="Calendar"/>
                                {selectedLocker ?
                                    <Select
                                        classNamePrefix="react-select"
                                        components={{DropdownIndicator}}
                                        styles={customStyles}
                                        options={options}
                                        value={selectedDuration ? options.find(option => option.value === selectedDuration) : null}
                                        onChange={(selectedOption) => setSelectedDuration(selectedOption ? selectedOption.value : null)}
                                        placeholder="How long?"
                                    /> : <p id={"selectSizeP"}>Select the locker size</p>}
                            </div>
                        </div>
                        <Modal
                            isOpen={showSummaryModal}
                            onClose={() => setShowSummaryModal(false)}>
                            <SummaryModalContent
                                selectedLocker={selectedLocker}
                                selectedDuration={selectedDuration}
                                handleCheckout={handleCheckout}
                                setShowSummaryModal={setShowSummaryModal}
                            />
                        </Modal>
                        <button
                            className={"c-button bookNowButton"}
                            onClick={openSummaryModal}
                            disabled={!selectedLocker || !selectedDuration}>
                            Book Now
                        </button>
                    </div>
                </div>
            </div>
            <Faqs/>
            <div className={"premiumInfoContainer"}>
                <div className={"premiumSubContainer"}>
                    <h1>Want your bags <br/> collected and delivered?</h1>
                    <div className={"premiumTextContainer"}>
                        <p>Discover our premium service</p>
                        <p>Don't feel like coming over to our store? Don't worry! We'll collect your luggage for you. Just text us via whatsapp and we'll come and get them (upon availability).</p>
                    </div>
                    <a className={"contactUsLink"} href="https://wa.me/935899178">
                        <button className="c-button contactUsButton">
                            <img src="/imgs/brands/whatsapp.svg" alt="WhatsApp"/>Contact us via WhatsApp
                        </button>
                    </a>
                </div>

            </div>
            <div className={"doubtsContainer"}>
                <div className={"doubtSubContainer container"}>
                    <h1>Need a hand?</h1>
                    <div className={"doubtTextContainer"}>
                        <p>If you have any doubts about our lockers or the way they should be used, please contact us via WhatsApp, we will get to you as soon as possible.</p>
                    </div>
                    <a className={"contactUsLink"} href="https://wa.me/935899178">
                        <button className="c-button contactUsButton">
                            <img src="/imgs/brands/whatsapp.svg" alt="WhatsApp"/>Contact us via WhatsApp
                        </button>
                    </a></div>
            </div>
            <div className={"partnersContainer"}>
                <h1>Partners</h1>
                <div className={"partnersSubContainer container"}>
                    <img src="/imgs/partners/swatter.svg" alt="SWATTER"/>
                    <img src="/imgs/partners/appx.svg" alt="APPX"/>
                </div>
            </div>
            <div className={"whereAreWeContainer"}>
                <h1>Where are we?</h1>
                <p>Travessa do Freitas n°9B <br/> 9000-042, Funchal</p>
                <MapComponent/>
            </div>
            <SuccessModal
                showSuccessModal={showSuccessModal}
                setShowSuccessModal={setShowSuccessModal}
                lockerData={lockerData}
                transactionDetails={transactionDetails}
            />
            <Footer/>
        </div>
    );
};

export default HomePage;
