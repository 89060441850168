import Cookies from "js-cookie";


export async function getUserDetails(jwt) {
    try {
        const response = await fetch(process.env.REACT_APP_API_URL + '/api/users/me', {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        });
        const data = await response.json();
        if (response.ok) {
            return data;
        } else {
            throw new Error(data.message || 'Error fetching user details');
        }
    } catch (error) {
        console.error('Error fetching user details:', error);
        throw error;
    }
}

export const handleLogin = async (userData, onRegisterSuccess) => {

    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/local`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                identifier: userData.identifier,
                password: userData.password,
            }),
        });

        const data = await response.json();

        if (response.ok) {
            Cookies.set('jwt', data.jwt, {expires: 7});
            const userDetails = await getUserDetails(data.jwt);
            onRegisterSuccess?.(userDetails);
            return {userDetails, error: null};
        } else {
            const errorMessage = data.error && data.error.message === "Invalid identifier or password"
                ? "Invalid Username/Email or Password"
                : data.error.message || 'Login failed. Please try again.';
            return {userDetails: null, error: errorMessage};
        }
    } catch (error) {
        console.error('Login error:', error);
        return {userDetails: null, error: 'An error occurred during login.'};
    }
};


export const handleRegister = async (userData, onRegisterSuccess) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/local/register`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(userData),
        });
        const data = await response.json();

        if (response.ok) {
            // Automatically log in the user after successful registration
            const loginResponse = await handleLogin({
                identifier: userData.email,
                password: userData.password,
            }, onRegisterSuccess);
            if (loginResponse.error) {
                console.error('Login error after registration:', loginResponse.error);
                return {error: loginResponse.error};
            }
            return {error: null};
        } else {
            return {error: data.message || 'Registration failed. Please try again.'};
        }
    } catch (error) {
        console.error('Registration error:', error);
        return {error: error.message || 'An error occurred during registration.'};
    }
};
