import React, {useRef} from 'react';
import {motion, useInView} from 'framer-motion';
import './LockerCard.scss';

const LockerCard = ({size, dimensions, price, imageSrc, altText, variants, transition, index, onCardClick, isSelected, isAvailable}) => {
    const ref = useRef(null);
    const isInView = useInView(ref, {once: true, margin: '-150px 0px'});
    const calculateDelay = (index) => {
        const delayPerItem = 0.2;
        return index * delayPerItem;
    };
    return (
        <motion.div className={`lockerCard ${isSelected ? 'selected' : ''} ${!isAvailable ? 'unavailable' : ''}`}
                    onClick={() => isAvailable && onCardClick({size, dimensions, price, imageSrc, altText})}
                    ref={ref}
                    variants={variants}
                    initial="hidden"
                    animate={isInView ? "visible" : "hidden"}
                    transition={{...transition, delay: isInView ? calculateDelay(index) : 0}}>
            <div className={"lockerInfoContainer"}>
                <div className={"lockerTypeContainer"}>
                    <div className="lockerSize">{size}</div>
                    <div className="lockerDimensions"><p>{dimensions}</p></div>
                </div>
                <div className="lockerPrice">From <br/>{price}<span>€/3h</span></div>
            </div>
            <div className="lockerImageContainer">
                <img src={imageSrc} alt={altText}/>
            </div>
        </motion.div>
    );
};

export default LockerCard;
